"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const md5 = require('nano-md5');
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const action_1 = require("@src/app/services/action");
class Md5Ctrl {
    constructor($scope, $filter, ConfigService, actionService) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.ConfigService = ConfigService;
        this.actionService = actionService;
        this.value = '';
    }
    $onInit() {
        this.$scope.$watch('$ctrl.value', () => {
            if (this.ConfigService.cookieSettings.auto_hash) {
                this.generate();
            }
        });
        (0, rxjs_1.combineLatest)([
            this.ConfigService.settings$.pipe((0, operators_1.pluck)('upper_hash')),
            this.ConfigService.settings$.pipe((0, operators_1.pluck)('per_line')),
        ]).subscribe(() => {
            this.generate();
        });
    }
    generate() {
        let output = '';
        if (this.value.length == 0) {
            this.output = undefined;
        }
        else {
            if (this.ConfigService.cookieSettings.per_line) {
                output = this.value.split('\n')
                    .filter((item) => item.length > 0)
                    .map((item) => md5(item))
                    .join('\n');
            }
            else {
                output = md5(this.value);
            }
            this.output = this.ConfigService.cookieSettings.upper_hash ? output.toUpperCase() : output.toLowerCase();
        }
    }
    clear() {
        this.value = '';
        this.output = undefined;
    }
    upload() {
        this.actionService.uploadFactory("text/*").then((value) => {
            this.$scope.$apply(() => {
                this.value = value;
            });
        }, (err) => {
            if (err)
                alert(err);
        });
    }
    download() {
        this.actionService.downloadFactory('md5_zone', this.output);
    }
    copy() {
        this.actionService.copyFactory(document.getElementById('widget__field'), this.output);
    }
}
Md5Ctrl.$inject = ['$scope', '$filter', 'ConfigService', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameMd5', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: Md5Ctrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('md5/');
    }]);
